@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap'); */

html,
body {
  padding: 0;
  margin: 0;
  background: rgb(221, 250, 198);
  background: linear-gradient(2.33deg,rgba(161, 229, 144, 0.5) 0.37%, rgba(143, 211, 244, 0.5) 30.98%,rgba(224, 253, 189, 0.5)  80.28%);
  font-size: 20px;
  line-height: 28px;
  min-height: 100vh;
  font-family: 'Montserrat',sans-serif;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Poppins', sans-serif;
  color:#202020 !important;
}

/* mobile phones  */
@media (max-width: 768px) {
  html,
  body {
    font-size: 14px;
    line-height: 21px;
    font-family: 'Montserrat', sans-serif;
  }
  h1,h2,h3,h4,h5,h6{
    font-family: 'Poppins', sans-serif;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  visibility: hidden;
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background: linear-gradient(91.76deg, #18c3e6 4.94%, #a1e690 94.92%); */
  background: #ccc;
}

.blink_me {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.25;
  }
}
